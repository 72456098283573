<template>
  <v-container>
    <v-main class="pa-0">
      <baseTitle name="我的游戏"></baseTitle>
      <img
        v-for="(item, index) in list"
        :key="index"
        :src="getImgSrc(item.id)"
        height="300"
        width="200"
        class="pa-1">
    </v-main>
  </v-container>
</template>

<script>
import baseTitle from '@/components/base/title'

export default {
  inject: ['theme'],
  components: {
    baseTitle
  },
  data: () => ({
    list: [],
    items: [
      { id: 'theWitcher' },
      { id: 'theInvisibleGuardian' },
      { id: 'unheard' },
      { id: 'thisWarOfMine' },
      { id: 'theScarOfTheSky' }
    ]
  }),
  created () {
    this.getList()
  },
  methods: {
    getList () {
      // TODO: 从接口取
      this.list = this.items
    },
    getImgSrc (id) {
      return '/imgs/game/' + id + '.jpg'
    },
    toDetail (id) {
      this.$router.push({
        name: 'gameDetail',
        params: {
          id
        }
      })
    }
  }
}
</script>
