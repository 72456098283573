<template>
  <v-alert class="pl-5" color="primary" border="left" elevation="2" colored-border :icon="icon">
    {{ name }}
  </v-alert>
</template>

<script>
export default {
  name: 'baseTitle',
  props: {
    name: {
      type: String
    },
    icon: {
      type: String
    }
  }
}
</script>
